<template>
    <div class="le-Tabs-container"  >
        <div :class="index==idx?'list-item active':'list-item'" v-for="(item,idx) in list"  :key="idx"  @click="tabItem(idx)" >{{item}}</div>
        <a-month-picker v-model="value"  style="width: 200px;" @change="onChange"  placeholder="选择月份"  />
    </div>
</template>

<script>
import moment from 'moment';
import tool from '@/utils/tool'
export default {
  name: "date-tabs",
  components: {
  },
  data() {
    return {
      index:0,
      value: moment(new Date())
    };
  },
  props:{
    list:{
      type:Array,
      default:()=>{
        return [
          'Q1',
          'Q2',
          'Q3',
          'Q4'
        ]
      }
    }
  },
  computed: {
  },
  mounted(){
    // 判断当前月份是哪个季度
    let date=new Date();
    let month=date.getMonth()+1;
    console.log(date.getFullYear(),date.getMonth()+1);
    if(month<4){
      this.index=0;
    }else if(month<7){
      this.index=1;
    }else if(month<10){
      this.index=2;
    }else{
      this.index=3;
    }
    let json={}
    json.start_at = moment(new Date()).format('YYYY-MM-01 00:00:00');
    json.close_at = moment(new Date()).endOf('month').format('YYYY-MM-DD 23:59:59');
    this.$emit('change',json)
  },
  methods: {
    onChange(date, dateString){
      console.log("onChange", moment(date).format('M'), dateString);
      let month = moment(date).format('M')
      this.index = 4;
      if(month<4){
        this.index=0
      }else if(month<7){
        this.index=1
      }else if(month<10){
        this.index=2
      }else{
        this.index=3
      }

      const json={
        start_at:moment(date).format('YYYY-MM-01 00:00:00'),
        close_at:moment(date).endOf('month').format('YYYY-MM-DD 23:59:59'),
      }
      tool.$emit('change',json)
      this.$emit('change',json)
    }, 
    tabItem(idx){
      const json={
        start_at:'',
        close_at:''
      }
      switch(idx){
        case 0:
          json.start_at = moment(new Date()).format('YYYY-01-01 00:00:00');
          json.close_at = moment(new Date()).format('YYYY-01-31 23:59:59');
          this.value = moment(new Date()).format('YYYY-01');
        break;
        case 1:
          json.start_at = moment(new Date()).format('YYYY-04-01 00:00:00');
          json.close_at = moment(new Date()).format('YYYY-04-30 23:59:59');
          this.value = moment(new Date()).format('YYYY-04');
        break;
        case 2:
          json.start_at = moment(new Date()).format('YYYY-07-01 00:00:00');
          json.close_at = moment(new Date()).format('YYYY-07-31 23:59:59');
          this.value = moment(new Date()).format('YYYY-07');
        break;
        case 3:
          json.start_at = moment(new Date()).format('YYYY-10-01 00:00:00');
          json.close_at = moment(new Date()).format('YYYY-10-31 23:59:59');
          this.value = moment(new Date()).format('YYYY-10');
        break;
      }
      console.log(json);
      this.index=idx
      tool.$emit('change',json)
      this.$emit('change',json)
    }
  }
};
</script>

<style lang="less" scoped>
.le-Tabs-container {
  display: flex;
  margin-right: 20px;
  .list-item{
    min-width: 70px;
    height: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(205, 206, 210, 0.5);
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
    font-weight: 400;
    color: #303133;
    margin-right: 20px;
    cursor: pointer;
    transition: .4s;
    padding: 0 10px;
    font-size: 14px;
  }
  .active{
    background: linear-gradient(-10deg, #69C6FF, #3E83FF);
    box-shadow: 0px 3px 5px 0px #A3BFFF;
    border-radius: 5px;
    color: #FFFFFF;
  }
}
</style>
