<template>
  <div class="dashboard-table-card-container">
    <le-card width="100%" style="padding-bottom: 20px">
      <template>
        <div class="container">
          <a-radio-group
            default-value="1"
            button-style="solid"
            style="margin-top: 15px; margin-left: 30px"
            @change="changeType"
            v-model="typeModel"
          >
            <a-radio-button value="1"> 挂靠 </a-radio-button>
            <a-radio-button value="2"> 经纪人 </a-radio-button>
          </a-radio-group>

          <div class="table-box">
            <a-table
              :columns="columns"
              :data-source="dataSource"
              :loading="loading"
              :pagination="pageOptions"
              @change="handleTableChange"
              size="middle"
            >
              <div slot="fcTitle">
                分成说明
                <a-popover title="当前分成">
                  <template slot="content">
                    <p>1,完成 职业主播基线，未完成流水基线 分成 = 50%</p>
                    <p>2,未完成 职业主播基线，完成流水基线 分成 = 50%</p>
                    <p>3,完成 职业主播基线，完成流水基线 分成 = 60%</p>
                    <p>4,完成 职业主播基线，完成流水目标(X1.5) 分成 = 70%</p>
                  </template>
                  <a-icon type="question-circle" />
                </a-popover>
              </div>

              <div slot="uname" slot-scope="row">
                <a
                  class="name-box"
                  :href="'https://space.bilibili.com/' + row.uid"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <div class="avatar" v-if="row.face_url">
                    <img :src="row.face_url" alt="" srcset="" />
                  </div>
                  <div class="name-uid">
                    <div>{{ row.name }}</div>
                    <div>UID:{{ row.uid }}</div>
                  </div>
                </a>
              </div>

              <div slot="income" slot-scope="row">
                {{ (row.total_income / 1000) * 2 }}
              </div>

      <div slot="lamination" slot-scope="row">
        {{ row.lamination  }}%
      </div>


              <div slot="money" slot-scope="row">
                {{ row.total_income / 1000 }}
              </div>

              <span slot="action" slot-scope="row">
                <a
                  @click="
                    visibleEdit = true;
                    rows = row;
                  "
                  v-if="isEdit"
                  >编辑目标</a
                >
                <a-divider type="vertical" v-if="isEdit"  />
                <a
                  @click="
                    visible = true;
                    dateData = row.professional_anchor_list;
                  "
                  >职业主播</a
                >
              </span>
            </a-table>
          </div>
        </div>
      </template>
    </le-card>

    <popView
      v-if="visible"
      :visible="visible"
      @handleOk="visible = false"
      @handleCancel="visible = false"
      :row="dateData"
    />
    <popEdit
      v-if="visibleEdit"
      :visible="visibleEdit"
      @handleOk="
        visibleEdit = false;
        getData();
      "
      @handleCancel="visibleEdit = false"
      :row="rows"
      :type="typeModel"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "id",
  },
  {
    title: "昵称/UID",
    scopedSlots: { customRender: "uname" },
    width: 200,
  },
  {
    title: "当前职业主播数",
    dataIndex: "new_professional_anchor",
    sorter: (a, b) => a.new_professional_anchor - b.new_professional_anchor
  },
  {
    title: "当前流水",
    dataIndex: "new_flow",
    sorter: (a, b) => a.new_flow - b.new_flow
  },
  {
    title: "职业主播基线",
    dataIndex: "expectation_professional_anchor",
  },
  {
    title: "流水基线",
    dataIndex: "expectation_flow",
  },
  {
    title: "流水目标(x1.5)",
    dataIndex: "objective_flow",
  },
  {
    // title: "当前分成(？)",
    slots: { title: "fcTitle" },
    scopedSlots: { customRender: "lamination" }
  },
  {
    title: "操作",
    width: 240,
    scopedSlots: { customRender: "action" },
  },
];

import { MixinList } from "./mixin";
import popView from "./popView";
import popEdit from "./popEdit";
export default {
  components: {
    popView,
    popEdit,
  },
  data() {
    return {
      columns,
      dataUrl: "v2/guildManage/sel",
      delUrl: "admin/layout/del",
      visible: false,
      visibleEdit: false,
      dateData: [],
      type: "uname",
      rows: {},
      typeModel:'1'
    };
  },
  computed: {},
  mixins: [MixinList],
  created() {
      let btn=window.localStorage.getItem('whiteBtns')
      if(btn){
        let btns=btn.split(',');
        if(btns.includes('manage-data-edit')){
          this.isEdit = true;
        }
      }
  },
  beforeMount() {},
  mounted() {},
  methods: {

    // 切换类型
    changeType(e){
      console.log(e.target.value,this.typeModel);
      if(e.target.value == "1"){
        this.dataUrl = "v2/guildManage/sel";
      }else{
        this.dataUrl = "v2/guildManage/broker";
      }
      this.getData();
    },

    //搜索
    onSearch(value) {
      if (!value) {
        delete this.queryParam.keyword;
        delete this.queryParam.search_by;
      } else {
        this.queryParam.keyword = value;
        this.queryParam.search_by = this.type;
      }
      this.queryParam.page = 1;
      console.log(this.queryParam);
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-table-card-container {
  .container {
    position: relative;
    .table-box {
      margin: 40px 30px;
      margin-top: 10px;
    }
  }
}

.btn-canle {
  width: 70px;
  height: 30px;
  background: linear-gradient(-10deg, #fd8a8f, #fa6a9d);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin-left: 10px;
}

.login {
  cursor: pointer;
  &:hover {
    color: #008df0;
  }
}

.name-box {
  display: flex;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
  .dashboard-table-card-container {
    .container {
      .title {
        padding-top: 10px;
        margin-left: 20px;
        font-size: 20px;
      }
      .le-button {
        position: absolute;
        top: 30px;
        right: 20px;
      }

      .table-box {
        margin: 20px;
        overflow-x: scroll;
      }
    }
  }
}
</style>
