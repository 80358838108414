<template>
  <div class="card-container">
    <le-card width="190px" height="100px">
      <template>
        <div class="container">
          <div class="title">
            {{ title }}
          </div>
          <div class="num-box">
            <div class="num">{{ num |handleMoney }}</div>
            <div class="unit">{{ unit }}</div>
          </div>
        </div>
      </template>
    </le-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      search: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    num: {
      type: [String, Number],
      default: "",
    },
    unit: {
      type: String,
      default: "元",
    }
  },
  filters:{
    handleMoney(money){
      if (money && money != null &&!isNaN(money)) {
        money = parseFloat(money).toFixed(2)
        money = String(money);
        var left = money.split('.')[0], right = money.split('.')[1];
        right = right ? (right.length >= 2 ? '.' + right.substr(0, 2) : '.' + right + '0') : '.00';
        var temp = left.split('').reverse().join('').match(/(\d{1,3})/g);
        return (Number(money) < 0 ? '-' : '') + temp.join(',').split('').reverse().join('') + right;
      } else if (money === 0) { // 注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
        return '0.00';
      } else {
        return '0.00';
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    searchClick() {
      console.log(this.search);
      if (!this.search) {
        this.$message.error("请填入主播UID");
        return false;
      }

      tool.$emit("searchOut", this.search);
    },
    changeSearch() {
      console.log("123");
      if (!this.search) {
        tool.$emit("clearOut");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card-container {
  margin:0 10px;
  .container {
    position: relative;
    .title {
      padding-top: 20px;
      margin-left: 15px;
      color: #333333;
    }
    .num-box{
      margin-left: 15px;
      margin-top: 10px;
      display: flex;
      align-items: flex-end;
      .num{
        font-size: 24px;
        font-weight: bold;
        margin-right: 5px;
      }
      .unit{
        font-size: 14px;
      }
    }

  }
}
</style>
