<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      title="编辑"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="600px"
    >

<a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >

    <a-form-item label="昵称">
      {{row.name}}
    </a-form-item>

    <a-form-item label="uid">
      {{row.uid}}
    </a-form-item>

    <a-form-item label="当前职业主播数">
      {{row.new_professional_anchor}}
    </a-form-item>

    <a-form-item label="职业主播基线">
      <a-input-number
        :min="0"
        v-decorator="formDecorators.expectation_professional_anchor"
        style="width: auto;"
      />
    </a-form-item>

    <a-form-item label="当前流水">
      {{row.new_flow}}
    </a-form-item>


    <a-form-item label="流水基线">
      <a-input-number
        :min="0"
        v-decorator="formDecorators.expectation_flow"
        style="width: auto;"
      />
    </a-form-item>

    </a-form>
    </a-modal>
  </div>
</template>

<script>

import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default {
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      SHOW_PARENT,
      list:[]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
    type:{
      type: [String, Number],
      default: '1',
    }
  },
  computed: {
    formDecorators() {
      let data = JSON.parse(JSON.stringify(this.row));
      return {
        expectation_flow: [
          "expectation_flow",
          {
            initialValue: data && data.expectation_flow,
          },
        ],
        expectation_professional_anchor: [
          "expectation_professional_anchor",
          {
            initialValue: data && data.expectation_professional_anchor,
          },
        ]
      };
    },
  },
  created(){
  },
  mounted() {
    
  },
  filters:{

  },
  methods: {
    handleOk(){
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id=this.row.id
          let url=''
          if(this.type=="1"){
            url="/v2/guildManage/upd"
          }else{
            url="/v2/guildManage/brokerUpd"
          }

          this.$api.post(url, values)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message.success("修改成功");
                this.$emit("handleOk");
              } else {
                this.$message.error(resp.message);
              }
              this.confirmLoading = false;
            })
            .catch((e) => {
              this.confirmLoading = false;
              throw e;
            });
        }else{
          this.confirmLoading = false;
        }
        })
    },
    handleCancel(){
      this.$emit('handleCancel')
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
@media screen and(max-width:750px) {
  
.list {
  display: block;
  margin-bottom: 0px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
    margin-bottom: 15px;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.label {
  width: 100px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 16px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
}

</style>
