<template>
    <div class="dashboard-search-card-container ">
      <le-card width="100%" height="250px" >
        <template>
          <div class="container">
            <div class="title-box">
              <div class="card-title" style="padding-top: 0;"  >公会数据</div>
            </div>
            <date-tabs style="margin-left: 30px" @change="changeTab" />
            <div :class="isShow?'card-box scroll-show':'card-box scroll-hide'" @mouseenter="mouseenter" @mouseleave="mouseleave">
              <card  
                title="总流水(月)"
                :num="json.total_flow"
                tips="时间段总交易流水"
              />
              <card 
                title="总目标(月)"
                :num="json.total_expectation_flow"
                tips="时间段旗下主播开播数量"
              />
              <card 
                title="公会流水(月)"
                :num="json.own_guild_flow"
                tips="时间段旗下主播开播数量"
              />
              <card 
                title="公会目标(月)"
                :num="json.own_guild_expectation_flow"
                tips="时间段旗下主播开播数量"
              />
              <card 
                title="挂靠流水(月)"
                :num="json.affiliated_guild_flow"
                tips="时间段旗下主播开播数量"
              />
              <card 
                title="挂靠目标(月)"
                :num="json.affiliated_guild_expectation_flow"
                tips="时间段旗下主播开播数量"
              />
            </div>
            
            <div class="tips-box">
              统计时间：{{dateData.start_at}}~{{dateData.close_at}}(今日数据有一定延迟，以次日结果为准)
            </div>
          </div>
        </template>
      </le-card>
    </div>
</template>

<script>
import tool from '@/utils/tool'
import card from './card.vue'
import dateTabs from './dateTabs.vue'
export default {
  components: { 
    card,
    dateTabs
  },
  data() {
    return {
      json:{},
      dateData:{
        start_at: '',
        close_at: '',
      },
      isShow:false
    };
  },
  computed: {
  },
  mounted(){
    tool.$on('statistics',(res)=>{
      this.json=res;
    })
  },
  methods: {
    mouseenter(e){
      this.isShow=true;
    },
    mouseleave(e) {
      this.isShow=false;
    },
    changeTab(json) {
      this.dateData=json
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-search-card-container {
.container{
  position: relative;
  .title-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }

  .card-box{
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0 20px;
    padding-top: 20px;
    height: 140px;
  }

  .scroll-show{
    overflow-y:hidden;
    overflow-x:scroll;
    &::-webkit-scrollbar{
      height: 4px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 4px;
      background: #aaa;
    }
  }
  .scroll-hide{
    overflow-x:hidden;
    &::-webkit-scrollbar{
      display: hidden;
    }
    &::-webkit-scrollbar-thumb{
      display: hidden;
    }
  }

    .tips-box {
      margin-top: 5px;
      margin-left: 30px;
      font-size: 12px;
      color: #aaa;
    }


}
}


@media screen and(max-width: 750px) {
.dashboard-search-card-container {
.container{
}
}
}

</style>
